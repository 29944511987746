<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">名称：</span>
					<el-input v-model="searchinput" placeholder="请输入名称" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>						
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="280" @getdata="gettable" @changswitch="changswitch">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showeidt(scope.row)" type="success" size="mini">
								<i class="el-icon-document" style="font-size: 12px;"></i>
								详情
							</el-button>	
							<el-button v-show="scope.row.qdtype!=2&&(scope.row.type==1||scope.row.type==2)"
								@click="canhui(scope.row)" type="primary" size="mini">
								<i class="el-icon-bell" style="font-size: 12px;"></i>
								参会
							</el-button>
							<!-- <el-button v-show="scope.row.qdtype!=2&&scope.row.type==3" @click="qiandao(scope.row)"
								type="success" size="mini">
								<i class="el-icon-s-check" style="font-size: 12px;"></i>
								签到
							</el-button> -->
							<el-button v-show="scope.row.qdtype!=2&&(scope.row.type==1||scope.row.type==2)"
								@click="qingjia(scope.row)" type="warning" size="mini">
								<i class="el-icon-edit-outline" style="font-size: 12px;"></i>
								请假
							</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>	
		
		<detail ref="mydetail" :istz='true'></detail>
		
		<!--请假理由-->
		<el-dialog
		  title="请假原因"
		  :visible.sync="showqj"
		  width="30%"
		  :before-close="clearqj">
		  <div>
			  <div style="display: flex;align-items: flex-start;">
				  <span>请假理由：</span>
				  <el-input v-model="qjliyou" placeholder="请输入" style="width: 70%;" type="textarea" :rows="4"></el-input>
			  </div>
			  <div style="display: flex;align-items: flex-start;margin-top: 20px;">
				  <span>代参加人：</span>
				  <div>
					  <div style="display: flex;align-items: center;">
					  	<el-button type="primary" size="small" @click="select">选择</el-button>								
					  </div>
					  <div>
					  	<span style="color: #999;">代参加人：</span>{{user_name}}
					  </div>
				  </div>
			  	
			  </div>
		  </div>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="clearqj">取 消</el-button>
		    <el-button type="primary" @click="postqingjia">确 定</el-button>
		  </span>
		</el-dialog>
		
		<selectuser ref="myselectuser" @selectedry="selectedry"></selectuser>
	</div>
</template>

<script>	
import selectuser from '@/components/select_user.vue'
import detail from '../meeting_list/components/detail.vue'
	export default{		
		components:{ detail,selectuser },
		data() {
			return{
				showqj:false,
				qjliyou: '',
				user_id: '',
				user_name: '',
				qjid:'',
				searchinput:'',
				tableHeader: [{
						prop: 'id',
						name: '序',
						width: '50'
					},					
					{
						prop: 'wymeeting_name',
						name: '会议名称',
						width: ''
					},
					{
						prop: 'room_name',
						name: '会议室',
						width: '200'
					},
					{
						prop: 'wymeeting_address',
						name: '地点',
						width: ''
					},
					{
						prop: 'wymeeting_addtime',
						name: '开始时间',
						width: '200'
					},
					{
						prop: 'zhuangtai',
						name: '状态',
						width: '120',
						type:'meeting_status'
					}
				],
				tableData: [],
			}
		},
		mounted() {
			this.gettable()
		},
		methods:{
			select(){
				let cxidlist = this.user_id?this.user_id.split(','):[]
				let cxnamelist = this.user_name?this.user_name.split(','):[]
				let list = []
				if(cxidlist.length>0){
					cxidlist.forEach((item,index)=>{
						let obj={
							name:cxnamelist[index],
							id:Number(item)
						}
						list.push(obj)
					})
				}				
				this.$refs.myselectuser.isdanxuan = true
				this.$refs.myselectuser.selectuser = list.length>0?list:[]
				this.$refs.myselectuser.dialogVisible = true
			},
			selectedry(e){
				this.user_id = e.map((item)=>{ return item.id })
				this.user_id = this.user_id.join(',')
				this.user_name = e.map((item)=>{ return item.name })
				this.user_name = this.user_name.join(',')
			},
			qingjia(row) {
				this.qjid = row.id
				this.showqj = true
			},
			postqingjia(){
				if(!this.qjliyou){
					this.$message.warning('请填写请假理由')
					return
				}
				this.$post({
					url: '/api/wymeetingtz/hybmqj',
					params: {
						id: this.qjid,
						type:4,
						liyou:this.qjliyou,
						duser_id:this.user_id
					}
				}).then((res) => {
					this.$message.success('操作成功')
					this.clearqj()
					this.gettable()
				})
			},
			clearqj(){
				this.showqj = false
				this.qjliyou=''
				this.user_id=''
				this.user_name=''
				this.qjid=''
			},
			qiandao(row){
				this.$confirm('请确认是否签到会议?', '提示', {
					confirmButtonText: '签到',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post({
						url: '/api/wymeetingtz/hyqd',
						params: {
							id: row.id,
							qdfs:1
						}
					}).then((res) => {
						this.$message.success('签到成功')
						this.gettable()
					})
				}).catch(() => {
					
				})
			},
			canhui(row) {
				this.$confirm('请确认是否参加会议?', '提示', {
					confirmButtonText: '参加',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post({
						url: '/api/wymeetingtz/hybmqj',
						params: {
							id: row.id,
							type:3
						}
					}).then((res) => {
						this.$message.success('参加成功')
						this.gettable()
					})
				}).catch(() => {
					
				})
			},
			showeidt(row){
				this.$post({
					url: '/api/wymeeting/details',
					params: {
						id:row.wymeeting_id,
						type:1
					}
				}).then((res) => {
					this.$refs.mydetail.form = res
					this.$refs.mydetail.isedite = false
					this.$refs.mydetail.dialogVisible = true		
				})
			},
			changswitch(row){
				this.$post({
					url: '/api/room/edit',
					params: row
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
				})
			},
			search(){
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable(){
				this.$post({
					url: '/api/wymeetingtz/index',
					params: {
						name: this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize
					}
				}).then((res) => {
					res.list.forEach((item, index) => {
						if (item.qdtype == 2) {
							item.zhuangtai = '已签到'
							return
						}
						if (item.type == 3) {
							item.zhuangtai = '已参加'
							return
						}
						if (item.type == 4) {
							item.zhuangtai = '已请假'
							return
						}
						item.zhuangtai = '待处理'
					})
					this.tableData = res.list
					this.$refs.mytable.total = res.data.count
				})
			},
			addnew(){
				this.$router.push('/system/meeting_add')
			},
			showdate(row){
				this.$refs.myroomdate.dialogVisible = true
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import 'meeting_tz.scss';
</style>